import { CarHireLocationSearchDataTransferObject } from '@reward-platform/ancillaries-schemas/location'
import { useState, useCallback } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useDebounce from '~/hooks/useDebounce/useDebounce'
import { getLocations } from '~/services/locationService'
import { getPartner } from '~/services/partnerService'
import { CarHireSearchForm, CarHireLocationOption } from '../SearchCarHireForm.types'
import { SEARCH_TYPE } from './useCarHireSearchForm'

/**
 * Set up and manage state for the locations autocomplete fields
 */
export function useCarHireSearchFormLocation(
  form: UseFormReturn<CarHireSearchForm>,
  defaultState: CarHireSearchForm['pickUpSearchString'] | CarHireSearchForm['dropOffSearchString'],
  fieldName: keyof CarHireSearchForm,
  controllerFieldName: keyof CarHireSearchForm
): CarHireLocationOption {
  const partner = getPartner().code
  const [searchString, setSearchString] = useState<string>(defaultState || '')
  const controllerFieldValue: string = form.getValues(controllerFieldName) as string

  const debouncedSearchString = useDebounce(searchString, 500)

  // When re-focusing the input field, pause re-fetching until user edits the query and instead show previous data.
  const querySameAsFullName = searchString === controllerFieldValue
  const canSearch =
    debouncedSearchString !== '' && debouncedSearchString === searchString && !querySameAsFullName

  const pickUpVendorBrandId =
    controllerFieldName === 'dropOffSearchString'
      ? form.getValues('pickUpLocation.vendorBrandId')
      : undefined

  const { data: locations, isLoading } = useQuery({
    queryKey: [fieldName, partner, debouncedSearchString],
    queryFn: () =>
      getLocations(partner, SEARCH_TYPE, debouncedSearchString, undefined, pickUpVendorBrandId),
    enabled: canSearch,
    placeholderData: keepPreviousData,
    meta: {
      notifyUser: true,
      errorMessage: 'Car Hire Error - could not get car hire locations',
    },
  })

  const noResultsFound = locations?.length === 0 && !isLoading && canSearch

  const clearLocation = useCallback(() => {
    form.setValue(fieldName, undefined)
    form.setValue(controllerFieldName, '')
    setSearchString('')
  }, [controllerFieldName, fieldName, form])

  const onInputFocus = useCallback(
    // Use shorter name as search query when re-focusing input field, as fullName will usually not yield results. Could be removed if search is enhanced in future.
    (e: { target: { select: () => void } }, openMenu: () => void) => {
      e.target.select()
      setSearchString(controllerFieldValue)
      openMenu()
      if (searchString === '') {
        clearLocation()
      }
    },
    [clearLocation, controllerFieldValue, searchString]
  )

  const handleAutocompleteChange = useCallback(
    (value?: string) => {
      const definedValue = value ?? ''

      setSearchString(definedValue)
      if (definedValue.length === 0) {
        form.setValue(
          fieldName,
          {
            type: '',
            id: '',
            fullName: '',
            name: '',
            countryCode: '',
            address: [],
            vendorBrandId: '',
          },
          { shouldDirty: true }
        )
      }
    },
    [fieldName, form]
  )

  const handleSuggestionClick = useCallback(
    (key: string) => {
      const location = (locations as CarHireLocationSearchDataTransferObject[]).find(
        (l: CarHireLocationSearchDataTransferObject & { key?: string }) => l.key === key
      )
      if (location) {
        setSearchString(location.fullName)
        form.clearErrors(controllerFieldName)
        form.setValue(fieldName, location, { shouldDirty: true })
        form.setValue(controllerFieldName, location.fullName, { shouldDirty: true })
      }
    },
    [locations, form, fieldName, controllerFieldName]
  )

  const swapLocations = useCallback(
    (newName: string, newLocation: CarHireLocationSearchDataTransferObject) => {
      form.setValue(fieldName, newLocation)
      form.setValue(controllerFieldName, newName)
      setSearchString(newName)
    },
    [controllerFieldName, fieldName, form]
  )

  return {
    isLoading,
    locations: isLoading || !canSearch || !locations ? [] : locations,
    searchString,
    setSearchString,
    noResultsFound,
    clearLocation,
    onInputFocus,
    handleAutocompleteChange,
    handleSuggestionClick,
    swapLocations,
  }
}
